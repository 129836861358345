import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleDewiCoverage, toggleEnhancedCoverage } from "../reducers/map.ts";

function ControlsMapLayer({ preview }) {
  const { dewiCoverageVisible, enhancedCoverageVisible } = useSelector(
    (state) => state.map
  );

  const dispatch = useDispatch();
  const toggleDewi = () => dispatch(toggleDewiCoverage());
  const toggleEnhanced = () => dispatch(toggleEnhancedCoverage());

  return (
    <aside
      className={`invisible md:visible fixed md:bottom-5 xl:top-12 ${
        preview ? "left-[20px]" : "left-[460px]"
      } z-50 h-[40px]`}
    >
      <button
        className="bg-white mx-2 pt-2 px-2 pb-1 rounded-sm"
        onClick={toggleEnhanced}
      >
        <input
          checked={enhancedCoverageVisible}
          readOnly
          type="checkbox"
          name=""
          id=""
          className="checkbox-black mb-2 mr-2 w-[20px] h-[20px] rounded relative top-[2px]"
        />
        <span>REALLY Enhanced Coverage</span>
      </button>
      <button
        className="bg-white mx-2 pt-2 px-2 pb-1 rounded-sm"
        onClick={toggleDewi}
      >
        <input
          checked={dewiCoverageVisible}
          readOnly
          type="checkbox"
          name=""
          id=""
          className="checkbox-black mb-2 mr-2 w-[20px] h-[20px] rounded relative top-[2px]"
        />
        <span>REALLY DeWi Coverage</span>
      </button>
    </aside>
  );
}

export default ControlsMapLayer;
